import axios from "axios";
import genese_token from "./components/const/token";

// Determine the baseURL based on window.location.hostname
let baseURL;

if (window.location.hostname === "uatuserocss.varosatech.com") {
  baseURL = "https://uatuserbackendocss.varosatech.com";
} else if (window.location.hostname === "userocss.varosatech.com") {
  baseURL = "https://userbackendocss.varosatech.com";
} else {
  baseURL = "http://3.110.175.163";
}

const api = axios.create({
  baseURL, // Set the baseURL based on the conditional logic
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

// Response interceptor
api.interceptors.response.use(
  (response) => response,
  (error) => {
    // Redirect to google.com
    console.log(error);
    // window.top.location.reload();

    return Promise.reject(error);
  }
);

export default api;
